import type { PlayerInfoForTable } from '@powerplay/core-minigames'
import { playersManager } from '@powerplay/core-minigames'

/**
 * Helper, ktory pripravuje data do tabulky
 */
export class TableStandingsHelper {

  /**
   * Vratenie dat pre tabulku
   * @returns Data
   */
  public getDataForTable(): PlayerInfoForTable[] {

    const dataStandings = playersManager.getStandings()

    // if (modes.isTournament()) {

    //   dataStandings.forEach(data => {

    //     data.results = []

    //   })

    // }

    return dataStandings

  }

}

export const tableStandingsHelper = new TableStandingsHelper()