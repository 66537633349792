import {
  MainCore,
  game,
  timeManager,
  CameraTypes,
  settings,
  SettingsTypes,
  gameStats,
  CustomEvents,
  THREE,
  AudioManager,
  CameraStates,
  cameraManager,
  AppWSM2021DifficultyTypes,
  playersConfig,
  PlayersSecondResultTypes,
  requestManager,
  TimesTypes,
  fpsManager,
  appWSM2021Config,
  modes,
  playersManager,
  corePhasesManager,
  CorePhases
} from '@powerplay/core-minigames'
import {
  audioConfig,
  batchingConfig,
  modelsConfig,
  texturesConfig,
  debugConfig,
  cameraConfig,
  gameConfig,
  trainingConfig,
  translatesReplacements,
  sponsorsConfig
} from './config'
import {
  TexturesNames,
  MaterialsNames,
  DisciplinePhases,
  ModelsNames
} from './types'
import { hill } from './entities/hill/Hill'
import { player } from './entities/player'
import { inputsManager } from './InputsManager'
import { disciplinePhasesManager } from './phases/DisciplinePhasesManager'
import { appWSM2021AllDifficultiesConfig } from './config/appWSM2021AllDifficultiesConfig'
import { materialsConfig } from './config/materialsConfig'
import { trainingTasks } from './modes/training/TrainingTasks'
import { stateManager } from './StateManager'
import { triggers } from './entities/trigger/Trigger'
import { shootingDirectionManager } from './shootingDirectionManager'
import { rifleManager } from './entities/rifle/RifleManager'
import { tutorialFlow } from './modes/tutorial/TutorialFlow'
import { trackpoints } from './entities/trackpoints/Trackpoints'
import { pathAssets } from '@/globals/globalvariables'
import { ParticleEffects } from './ParticleEffects'
import * as Sentry from '@sentry/vue'
import {
  debugState,
  trainingState,
  tutorialState
} from '@/stores'
import { loadingState } from '@powerplay/core-minigames-ui'

/**
 * Hlavna trieda pre disciplinu
 */
export class Main {

  /** Hlavna trieda z CORE */
  private mainCore: MainCore

  // Aktualna pozicia kamery
  private actualCameraPosition = new THREE.Vector3()

  // Aktualna rotacia kamery
  private actualCameraQuaternion = new THREE.Quaternion()

  /** Pause prveho tutorialu */
  private pausedFirstTutorial = false

  /** zoznam ignorovanych modelov */
  private ignoredModels: string[] = []

  /** Partikle Effect */
  private particleEffects!: ParticleEffects

  /**
   * Konstruktor
   */
  public constructor() {

    for (const [key, value] of Object.entries(modelsConfig)) {

      if (value.ignoreInModes?.includes(modes.mode)) {

        this.ignoredModels.push(key)

      }

    }

    this.addListeners()
    // pripravenie konfigov pre WSM 2021 - musime kontrolvat takto kvoli typescriptu
    if (modes.isAppWSM2021()) {

      appWSM2021Config.data = appWSM2021AllDifficultiesConfig[
        modes.mode as unknown as AppWSM2021DifficultyTypes // small TS hack :)
      ]

    }

    if (modes.isTutorial()) {

      // nastavime loading pre tutorial TODO zmen text na nazov discipliny
      loadingState().tutorial = {
        isEnabled: true,
        text: 'disciplineName2'
      }

    }

    /*
     * Nastavenie players konfigov, aby sa dobre zoradovali a mali dobre empty vysledky
     * nemusime zatial nic nastavovat, lebo berieme default hodnoty z konfigu
     */
    this.setPlayersConfig()
    /**
     * Nastavenie specialnych sponzorov
     */
    this.setSpecialSponsor()

    // nastavime penalizaciu
    timeManager.setPenaltySecondsForOne(gameConfig.penaltySeconds)

    // spustime CORE veci a pokial vsetko je v pohode, pusti sa INIT metoda
    this.mainCore = new MainCore(
      {
        meshesCastShadows: materialsConfig[MaterialsNames.skier].meshesArray || [],
        materials: materialsConfig,
        callbacks: {
          inputs: {
            callbackLeft: inputsManager.onKeyLeft,
            callbackRight: inputsManager.onKeyRight,
            callbackUp: inputsManager.onKeyUp,
            callbackDown: inputsManager.onKeyDown,
            callbackAction: inputsManager.onKeyAction,
            callbackAction2: inputsManager.onKeyAction2,
            callbackAction3: inputsManager.onKeyAction3,
            callbackExit: inputsManager.onKeyExit,
            callbackPrepareVideo: inputsManager.onKeyPrepareVideo
          },
          setSpecialDataFromInitRequest: this.setSpecialDataFromInitRequest,
          createAssets: this.createAssets,
          beforeGameStart: this.beforeGameStart,
          updateBeforePhysics: this.updateBeforePhysics,
          updateAfterPhysics: this.updateAfterPhysics,
          updateAnimations: this.updateAnimations
        },
        batching: batchingConfig,
        debugConfig,
        numberOfAttempts: gameConfig.numberOfAttempts,
        inputSchema: 'biathlon'
      },
      translatesReplacements,
      {
        textures: texturesConfig,
        models: modelsConfig,
        audio: audioConfig
      }
    )

    this.initialSetup()

  }

  /**
   * Nastavenie specialneho sponzora
   */
  private setSpecialSponsor(): void {

    const timeNow = Date.now()
    const random = THREE.MathUtils.randFloat(0, 1)

    // zatial kontrolujeme iba IBU sponzora
    const {
      name,
      timestampFrom,
      timestampTo,
      active,
      percent,
      replacementsWithVariants,
      replacementsBasic,
      variantsEverywhere,
      variantsNotInSpecialTournament
    } = sponsorsConfig.ibu

    const isActive = active && (timeNow >= timestampFrom && timeNow <= timestampTo) && random <= percent

    const variants = variantsEverywhere
    if (requestManager.tournamentType === 'tournament_biathlon') variants.push(...variantsNotInSpecialTournament)

    const variantIndex = Math.floor(Math.random() * variants.length)

    let i = 0

    replacementsWithVariants.forEach((replacement) => {

      for (i = 0; i < variants.length; i += 1) {

        if (isActive && i === variantIndex) {

          // nechavame ten variant
          delete texturesConfig[replacement.texture]
          materialsConfig[replacement.material].textureName = `${replacement.texture}-${name}-${variants[i]}`

        } else {

          // davame prec tento variant
          delete texturesConfig[`${replacement.texture}-${name}-${variants[i]}`]

        }

      }

    })

    replacementsBasic.forEach((replacement) => {

      if (isActive) {

        delete texturesConfig[replacement.texture]
        materialsConfig[replacement.material].textureName = `${replacement.texture}-${name}`

      } else {

        delete texturesConfig[`${replacement.texture}-${name}`]

      }

    })

  }

  /**
   * Metoda na overenie a zobrazenie FPS
   */
  private checkFpsRequest(): void {

    if (stateManager.getFpsStarted()) {

      const settingsQuality = settings.getSetting(SettingsTypes.quality)
      const fpsData = {
        averageFps: fpsManager.getAverageFpsByQuality(settingsQuality),
        actualFps: fpsManager.getActualFpsByQuality(settingsQuality),
        actualAverageFps: fpsManager.getActualAverageFps()
      }
      stateManager.setFpsData(fpsData)

    }

  }

  /**
   * Pridanie listenerov
   */
  private addListeners() {

    window.addEventListener(CustomEvents.readyForDisciplineInit, this.init)
    window.addEventListener(CustomEvents.loadingProgress, this.loadingProgress)
    // nastavime pocuvanie na zaciatok disciplinovej fazy z CORE
    window.addEventListener(
      CustomEvents.startDisciplinePhase,
      disciplinePhasesManager.setStartPhase
    )

  }

  /**
   * Inicializacny setup
   */
  private initialSetup() {

    const localEnv = Number(import.meta.env.VITE_APP_LOCAL) === 1
    this.mainCore.setIsLocalEnv(localEnv)
    game.setIsLocalEnv(localEnv)

    // lokalne si davame ID discipliny, aby sme nemuseli menit v GET parametroch stale
    if (localEnv) requestManager.disciplineID = 2

    AudioManager.PATH_ASSETS = pathAssets
    disciplinePhasesManager.create()

    /*
     * listener na zistenie appky, ze sme v background mode a mame dat pauzu, aby sme setrili
     * prostriedky a aby nehrali zvuky
     */
    window.addEventListener(CustomEvents.toggleBackgroundMode, () => {

      tutorialState().settings = true

    }, false)

  }

  /**
   * Vratenie ignorovanych nazvov textur
   * @returns Pole nazvov ignorovanych textur
   */
  private getIgnoredTexturesNames(): string[] {

    const allRaceTextures = [
      TexturesNames.skierRaceBlackMan,
      TexturesNames.skierRaceBlackWoman,
      TexturesNames.skierRaceMulattoMan,
      TexturesNames.skierRaceMulattoWoman,
      TexturesNames.skierRaceWhiteMan,
      TexturesNames.skierRaceWhiteWoman
    ]

    const usedTextures: string[] = []

    // pridame hraca
    const playerInfo = playersManager.getPlayer()
    usedTextures.push(`${playerInfo.sex}/${TexturesNames.skierRacePrefix}${playerInfo.race}`)

    /*
     * pridame superov, ak su
     * ...
     */

    // vysledok bude rozdiel dvoch poli
    return allRaceTextures.filter(x => !usedTextures.includes(x))

  }

  /**
   * Inicializacia main core
   */
  public init = (): void => {

    this.mainCore.init(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      this.getIgnoredTexturesNames(),
      TexturesNames.skierRacePrefix
    )

    const tweenSettingsForCameraStates = modes.isTrainingMode() ?
      trainingConfig.tweenSettingsForCameraStates :
      cameraConfig.tweenSettingsForCameraStates

    this.mainCore.setTweenSettingsForStates(tweenSettingsForCameraStates)

    cameraManager.changeBaseRenderSettings(undefined, 850)
    trainingTasks.initTraining()
    timeManager.setActive(TimesTypes.total, true)

    // UI update
    stateManager.allowDirectionState()

    triggers.splitTimeManager.setSplitCount()

  }

  /**
   * Nastavenie konfigu pre hracov
   */
  private setPlayersConfig(): void {

    playersConfig.secondResultType = PlayersSecondResultTypes.numberArray

  }

  /**
   * Zobrazenie progresu loadingu
   */
  private loadingProgress = (): void => {

    gameStats.setNextLoadingPart()
    loadingState().loadingProgress = gameStats.getLoadingProgress()

  }

  /**
   * Nastavenie specialnych dat z init requestu
   * @param data - Specialne data
   */
  private setSpecialDataFromInitRequest = (data: unknown): void => {

    console.log(data)

    Sentry.setContext('minigame', { id: requestManager.MINIGAME_START_ID })

  }

  /**
   * Nastavenie assetov
   */
  private createAssets = (): void => {

    hill.create()

    let position
    if (gameConfig.skipToFinish.active) position = gameConfig.skipToFinish.position
    if (this.isAllowedSkier()) player.create(position)
    rifleManager.setRifleObject()
    triggers.create()
    this.setUpDebug()

    // Partikle
    this.particleEffects = new ParticleEffects()

  }

  /**
   * puts singletons into window object
   */
  private setUpDebug(): void {

    if (!Number(import.meta.env.VITE_APP_LOCAL)) return

    const debug = {
      hill,
      inputsManager,
      player,
      disciplinePhasesManager,
      cameraManager: cameraManager,
      setHUDVisibility: () => (debugState().isHudActive = true),
      pauseGame: () => {

        if (game.paused) game.resumeGame()
        else game.pauseGame()

      },
      scene: game.scene,
      game,
      THREE
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).debug = debug

  }

  /**
   * Nastavenie alebo spustenie veci pred startom hry
   */
  private beforeGameStart = (): void => {

    stateManager.setBeforeGameStartPhase()
    trackpoints.init()

    // nastavime plynutie casu
    this.setTimeSpeedRunning()

  }

  /**
   * Nastavenie koeficientu plynutia casu podla atributov
   */
  private setTimeSpeedRunning(): void {

    const playerStrength = playersManager.getPlayer().attribute.total

    const { above1000, under1000, under100 } = gameConfig.timeSpeedRunningCoefs

    if (playerStrength > 1000) {

      gameConfig.timeSpeedRunning *= (1 - ((playerStrength - 1000) * under1000))

    } else if (playerStrength > 100) {

      gameConfig.timeSpeedRunning *= (((1000 - playerStrength) * above1000) + 1)

    } else {

      const coef = ((100 - playerStrength) * under100) + 1
      gameConfig.timeSpeedRunning = gameConfig.timeSpeedRunningUnder100 * coef

    }

    console.log('Plynutie casu (timeSpeedRunning) je ', gameConfig.timeSpeedRunning)

  }

  /**
   * Spustenie veci v update pred vykonanim fyziky
   */
  private updateBeforePhysics = (): void => {

    disciplinePhasesManager.update()
    this.checkFpsRequest()

  }

  /**
   * Spustenie veci v update po vykonani fyziky
   */
  private updateAfterPhysics = (delta: number): void => {

    if (
      !corePhasesManager.isActivePhaseByType(CorePhases.intro) &&
      !corePhasesManager.isActivePhaseByType(CorePhases.discipline)
    ) return

    this.particleEffects.update()

    if (requestManager.isFirstTrainingTutorial() && !this.pausedFirstTutorial) {

      this.pausedFirstTutorial = true
      console.log(requestManager.TUTORIAL_ID)
      trainingState().firstTutorialMessage = true
      game.pauseGame()
      return

    }
    player.updateAfterPhysics(hill.hillMesh, this.isAllowedSkier())

    this.actualCameraPosition.copy(player.getPosition())
    this.actualCameraQuaternion.copy(player.getQuaternion())

    if (disciplinePhasesManager.getActualPhase() === DisciplinePhases.shooting) {

      this.actualCameraPosition.copy(shootingDirectionManager.targetPoint.position)

    }

    cameraManager.move(
      this.actualCameraPosition,
      this.actualCameraQuaternion,
      delta,
      triggers.cameraCloserTriggerActivated ? [] : [hill.hillMesh],
      cameraConfig.distanceFromGround,
      cameraManager.isThisCameraState(CameraStates.disciplineOutro)
    )

    stateManager.setUpdateTimeState()

    triggers.splitTimeManager.update()

    if (modes.isTutorial()) tutorialFlow.update()

  }

  /**
   * Spustenie vykonavania vsetkych animacii
   * @param delta - Delta
   */
  private updateAnimations = (delta: number): void => {

    if (this.isAllowedSkier()) player.updateAnimations(delta)

  }

  /**
   * Zmena kamery na debug, ak by sme potrebvalo
   */
  public changeCameraToDebug = (): void => {

    cameraManager.setCamera(CameraTypes.debug)

  }

  /**
   * zistime ci je povoleny model skier
   * @returns True, ak je povoleny
   */
  private isAllowedSkier(): boolean {

    return this.isAllowedModel(ModelsNames.skier)

  }

  /**
   * zistime ci je povoleny model
   * @param model - Nazov modelu
   * @returns True, ak je model povoleny
   */
  private isAllowedModel(model: ModelsNames): boolean {

    return !this.ignoredModels.includes(model)

  }

}
