import {
  MaterialsNames,
  TexturesNames
} from '../types'

/**
 * Specialny konfig pre sponzorov v hre
 */
export const sponsorsConfig = {

  // reklama na IBU
  ibu: {
    name: 'ibu',
    active: true,
    timestampFrom: 1733698800000, // 9.12.2024
    timestampTo: 1756677599000, // 31.8.2025
    percent: 1, // 100% v danom case
    variantsEverywhere: ['sponsor1', 'sponsor2', 'sponsor3', 'sponsor4', 'sponsor5', 'sponsor6'],
    variantsNotInSpecialTournament: ['pps'],
    replacementsWithVariants: [
      {
        material: MaterialsNames.ads,
        texture: TexturesNames.ads
      },
    ],
    replacementsBasic: [
      {
        material: MaterialsNames.startAds,
        texture: TexturesNames.startAds
      },
      {
        material: MaterialsNames.skier,
        texture: TexturesNames.skierClothes
      },
    ]

  }

}
